<template>
  <div class="admin-view-container">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item label="变更原因" prop="orderFlowType" style="width: 18%">
          <el-select v-model="tableQuery.orderFlowType" placeholder="全部" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option value="1" label="系统充值"></el-option>
            <el-option value="10" label="服务结算"></el-option>
            <el-option value="20" label="退款结算"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" style="width: 25%">
          <el-date-picker
            v-model="pickDateTime"
            @change="dataPickerHandler"
            :default-time="['00:00:00', '23:59:59']"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <admin-space size="medium" class="admin-methods-btns" align="center">
      <el-button size="medium" icon="el-icon-download" class="admin-btn" @click="openExcel" type="primary">
        导出数据
      </el-button>
    </admin-space>
    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column prop="orderFlowNo" label="流水号">
          <template slot-scope="scope">
            <el-link type="primary" @click="openDetail(scope.row)">{{ scope.row.orderFlowNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="creationTime" label="创建时间">
          <template slot-scope="scope">
            {{ $utils.formatDateFull(scope.row.creationTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="orderFlowAmount" label="金额变更(元)">
          <template slot-scope="scope">
            <span
              :style="scope.row.orderFlowType == 1 || scope.row.orderFlowType == 20 ? 'color: #34b377' : 'color: #ff422d'">
              {{ scope.row.orderFlowType == 1 || scope.row.orderFlowType == 20 ? '+' : '-' }}{{ scope.row.orderFlowAmount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="orderFlowType" label="变更原因" :formatter="$formatter.dict($dict.orderFlowType)">
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {schBalanceGetList, schBalanceExportGetList} from '@/api/index';
import tableMinx from '@/mixin/table-page';
export default {
  mixins: [tableMinx(schBalanceGetList)],
  data() {
    return {
      loading: false,
      pickDateTime: [],
      tableQuery: {
        orderFlowType: '',
        startTime: '',
        endTime: '',
      },
    };
  },
  methods: {
    openExcel() {
      schBalanceExportGetList(this.tableQuery).then((res) => {
        if (res.status == 200) {
          this.$utils.downloadFile(res);
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    // 日期选中
    dataPickerHandler() {
      if (this.pickDateTime) {
        this.tableQuery.startTime = Date.parse(new Date(this.pickDateTime[0])) / 1000;
        this.tableQuery.endTime = Date.parse(new Date(this.pickDateTime[1])) / 1000;
      } else {
        this.tableQuery.startTime = '';
        this.tableQuery.endTime = '';
      }
    },

    async openDetail(row) {
      let vNode = this.$createElement((await import('./detail')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: row.orderFlowType == 1 ? '充值记录' : '详细信息',
        width: row.orderFlowType == 1 ? '600px' : '800px',
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style></style>
